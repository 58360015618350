<template>
  <v-expansion-panels
    class="ExpansionComponent ExpansionBordered mt-3"
    v-model="accountPanel"
  >
    <v-expansion-panel :disabled="loadingPanel">
      <!--TITULO DEL ACORDEON-->
      <v-expansion-panel-header
        @click="changeStateExpansive()"
        class="ExpansionTitle"
        expand-icon=""
      >
        Account information

        <div class="ExpansionState HideOnMovil">
          {{ stateExpansiveMessage }}
        </div>
        <template v-slot:actions>
          <v-icon class="iconExpand">
            {{ iconExpansive }}
          </v-icon>
        </template>
      </v-expansion-panel-header>
      <!--CONTENIDO DEL ACORDEON-->
      <v-expansion-panel-content>
        <div class="ExpandContent">
          <!--INPUTS-->
          <div class="ExpansionInputContent mb-7">
            <v-text-field
              v-model.trim="$v.accountInformation.insuredName.$model"
              @input="$v.accountInformation.insuredName.$touch()"
              @blur="$v.accountInformation.insuredName.$touch()"
              required
              :error-messages="
                requiredInputVuelidateParent(
                  'insuredName',
                  'accountInformation'
                )
              "
              label="Insured Name"
            ></v-text-field>
          </div>
          <div class="ExpansionInputContent mb-7">
            <v-text-field
              v-model.trim="$v.accountInformation.name.$model"
              @input="$v.accountInformation.name.$touch()"
              @blur="$v.accountInformation.name.$touch()"
              required
              :error-messages="
                requiredInputVuelidateParent('name', 'accountInformation')
              "
              label="Name"
            ></v-text-field>
          </div>
          <div class="ExpansionInputContent mb-7">
            <v-select
              v-model.trim="$v.accountInformation.country.$model"
              @input="$v.accountInformation.country.$touch()"
              @blur="$v.accountInformation.country.$touch()"
              required
              :error-messages="
                requiredInputVuelidateParent('country', 'accountInformation')
              "
              label="Country"
              :items="countries"
              item-text="description"
              item-value="id"
              return-object
              :loading="loadingCountries"
              :disabled="countries.length === 0"
            ></v-select>
          </div>
          <div class="ExpansionInputContent mb-7">
            <v-select
              v-model.trim="$v.accountInformation.typeOfRisk.$model"
              @input="
                $v.accountInformation.typeOfRisk.$touch();
                changeTypeOfRisk($v.accountInformation.typeOfRisk.$model.key);
              "
              @blur="
                $v.accountInformation.typeOfRisk.$touch();
                changeTypeOfRisk($v.accountInformation.typeOfRisk.$model.key);
              "
              @change="resetActivities()"
              required
              :error-messages="
                requiredInputVuelidateParent('typeOfRisk', 'accountInformation')
              "
              label="Line of Risk"
              :items="risk_type"
              item-text="key"
              item-value="id"
              :hint="`${
                $v.accountInformation.typeOfRisk.$model.description ||
                'Select a Line of Risk'
              }`"
              return-object
              :loading="loadingRisks"
              :disabled="risk_type.length === 0"
            ></v-select>
          </div>

          <div class="ExpansionInputContent">
            <v-select
              v-model.trim="$v.accountInformation.activity.$model"
              @input="$v.accountInformation.activity.$touch()"
              @blur="$v.accountInformation.activity.$touch()"
              required
              :error-messages="
                requiredInputVuelidateParent('activity', 'accountInformation')
              "
              label="Activity"
              :items="filteredActivities"
              :disabled="activityDisabled"
              item-text="description"
              item-value="id"
              return-object
              :loading="loadingActivities"
            ></v-select>
          </div>
          <div class="ExpansionInputContent">
            <v-select
              v-model.trim="$v.accountInformation.currency.$model"
              @input="$v.accountInformation.currency.$touch()"
              @blur="$v.accountInformation.currency.$touch()"
              required
              :error-messages="
                requiredInputVuelidateParent('currency', 'accountInformation')
              "
              label="Currency"
              :items="currencies"
              item-text="key"
              item-value="id"
              :hint="`${
                $v.accountInformation.currency.$model.region || 'Region'
              }, ${
                $v.accountInformation.currency.$model.description ||
                'Description'
              }`"
              return-object
              :loading="loadingCurrencies"
              :disabled="currencies.length === 0"
            ></v-select>
          </div>
          <div class="ExpansionInputContent">
            <v-select
              v-model.trim="$v.accountInformation.broker.$model"
              @input="$v.accountInformation.broker.$touch()"
              @blur="$v.accountInformation.broker.$touch()"
              label="Broker"
              :items="availableBrokers"
              item-text="name"
              item-value="id"
              hint="Select a Broker"
              clearable
              :loading="loadingBrokers"
              :disabled="availableBrokers.length === 0"
            ></v-select>
          </div>
          <div class="ExpansionInputContent">
            <v-select
              v-model.trim="$v.accountInformation.cedent.$model"
              @input="$v.accountInformation.cedent.$touch()"
              @blur="$v.accountInformation.cedent.$touch()"
              required
              :error-messages="
                requiredInputVuelidateParent('cedent', 'accountInformation')
              "
              label="Cedent"
              :items="availableCedents"
              item-text="name"
              item-value="id"
              hint="Select Cedent"
              :loading="loadingCedents"
              :disabled="availableCedents.length === 0"
            ></v-select>
          </div>

          <div class="ExpansionInputContent HideOnMovil"></div>

          <!--SEGUNDO ACORDEON-->
          <v-expansion-panels
            class="SubExpansionComponent mt-5"
            v-model="contactPanel"
          >
            <v-expansion-panel>
              <!--TITULO DEL ACORDION-->
              <v-expansion-panel-header
                @click="changeSubExpansion"
                class="ExpansionTitle d-flex justify-start"
                expand-icon=""
              >
                <template v-slot:actions>
                  <div class="subIconCont d-flex justify-start align-center">
                    <v-icon class="iconExpand mr-1">
                      {{ iconSubExpansion }}
                    </v-icon>

                    <div class="SubExpansionTitle">Contact</div>
                  </div>
                </template>
              </v-expansion-panel-header>
              <!--CONTENIDO DEL ACORDION-->
              <v-expansion-panel-content>
                <div class="ExpandContent">
                  <v-form
                    class="SubForm"
                    v-model="validContacts"
                    lazy-validation
                    ref="contactsForm"
                    @submit.prevent="null"
                  >
                    <div class="ExpandContent">
                      <div class="ExpansionInputContent">
                        <v-autocomplete
                          v-model.trim="value"
                          :error-messages="requiredInputVuelidate('value')"
                          :items="items"
                          :search-input.sync="search"
                          :loading="loadingItems"
                          placeholder="Start typing to Search"
                          label="Search contact by Name or Email"
                          @change="addValue"
                          item-value="id"
                          :filter="filterAutocomplete"
                          return-object
                          aria-autocomplete="nope"
                          autocomplete="nope"
                          :no-data-text="customText"
                        >
                          <template slot="selection" slot-scope="{ item }">
                            {{ item.name }} ({{ item.email }})
                          </template>
                          <template slot="item" slot-scope="{ item }">
                            <template v-if="typeof item !== 'object'">
                              <v-list-item-content
                                v-text="item.name"
                              ></v-list-item-content>
                            </template>
                            <template v-else>
                              <v-list-item-content>
                                <v-list-item-title
                                  v-text="item.name"
                                ></v-list-item-title>
                                <v-list-item-subtitle
                                  v-text="item.email"
                                ></v-list-item-subtitle>
                              </v-list-item-content>
                            </template>
                          </template>
                        </v-autocomplete>
                      </div>
                    </div>
                  </v-form>
                  <!--TABLA-->
                  <UsersTableAccount
                    v-if="selectedItems && selectedItems.length > 0"
                    :selectedItems="selectedItems"
                    @deleteContact="removeValue"
                  />
                </div>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </div>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>
<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';

import { stateExpansiveManager } from '@/mixins/subscription.js';
import { formValidations } from '@/mixins/formValidations';

/* vuelidate mixin & validators */
import { validationMixin } from 'vuelidate';
import { required, requiredIf, minLength } from 'vuelidate/lib/validators';

import UsersTableAccount from '@/components/subscription/submission/UsersTableAccount.vue';

export default {
  name: 'AccountInformation',
  mixins: [stateExpansiveManager, formValidations, validationMixin],
  components: {
    UsersTableAccount,
  },
  data() {
    return {
      /* paneles */
      accountPanel: null,
      contactPanel: null,
      /* valids */
      validContacts: true,
      activityDisabled: true,
      itemsTypeOfRisk: ['CAR', 'CPE', 'CECR', 'PRO', 'EAR'],
      /* loaders */
      loadingItems: false,
      loadingPanel: false,
      loadingRisks: false,
      loadingBrokers: false,
      loadingCedents: false,
      loadingContacts: false,
      loadingCountries: false,
      loadingActivities: false,
      loadingCurrencies: false,
      /* contacts */
      value: null,
      search: null,
      selectedItems: [],
    };
  },
  beforeMount() {
    this.resetAccountInformation();
    this.resetContactsInformation();
  },
  async mounted() {
    await this.initData();
  },
  computed: {
    ...mapGetters([
      'contacts',
      'countries',
      'risk_type',
      'currencies',
      'activities',
      'activitiesById',
      'availableBrokers',
      'availableCedents',
      'accountInformation',
      'availableBrokersById',
      'availableBrokersCedents',
      'availableBrokersCedentsByUUID',
      'documents',
    ]),
    filteredActivities() {
      const id =
        this.accountInformation.typeOfRisk.id ||
        this.accountInformation.typeOfRisk;
      if (typeof id === 'number') {
        this.enableActivities();
        return this.activitiesById(id);
      }
      return [];
    },
    selectAllContacts: {
      get() {
        return this.selectedItems.length === this.contactsItems.length;
      },
    },
    selectSomeContact: {
      get() {
        return this.selectedItems.length > 0 && !this.selectAllContacts;
      },
    },
    icon: {
      get() {
        if (this.selectAllContacts) return 'mdi-close-box';
        if (this.selectSomeContact) return 'mdi-minus-box';
        return 'mdi-checkbox-blank-outline';
      },
    },
    isOptional: {
      get() {
        return true;
      },
    },
    items: {
      get() {
        const filteredArray = this.contacts.filter((x) => {
          if (this.selectedItems.some((v) => v.id === x.id)) return false;
          return true;
        });
        return filteredArray;
      },
    },
    selectedBroker: {
      get() {
        return this.$v.accountInformation.broker.$model;
      },
    },
    selectedCedent: {
      get() {
        return this.$v.accountInformation.cedent.$model;
      },
    },
    customText: {
      get() {
        if (!this.selectedBroker && !this.selectedCedent)
          return 'Select a broker/cedent to see their contacts.';
        if (!Array.from(this.contacts) || Array.from(this.contacts).length <= 0)
          return 'Type some data.';
        return 'No data avilable.';
      },
    },
  },
  watch: {
    '$route.params.subscriptionId': async function (id) {
      this.resetAccountInformation();
      this.resetContactsInformation();

      await this.initData();
    },
    selectedItems: function (val) {
      this.$v.contactsInformation.$model = val;
      this.$v.contactsInformation.$touch();
    },
    search: async function (query) {
      const lit = 'loadingItems';

      if (this[lit]) return;

      this[lit] = !this[lit];

      await this.filterContactsByType({
        contact: [
          { type: 1, id: this.selectedBroker || 0 },
          { type: 2, id: this.selectedCedent || 0 },
        ],
        query,
      });

      this[lit] = false;
    },
  },
  validations: {
    accountInformation: {
      insuredName: { required },
      name: { required },
      country: { required },
      typeOfRisk: { required },
      activity: {
        required: requiredIf(({ typeOfRisk: { key } }) => key != 'CPE'),
      },
      currency: { required },
      broker: {
        required: requiredIf(function () {
          return !this.isOptional;
        }),
      },
      cedent: { required },
    },
    contactsInformation: {
      required: requiredIf(function () {
        return !this.isOptional;
      }),
    },
    value: {
      required: requiredIf(function () {
        if (this.selectedItems && this.selectedItems.length > 0) return false;
        return true;
      }),
    },
    selectedItems: { required },
  },
  methods: {
    /*
      ENVIA LOS DATOS AL COMPONENTE PADRE.
      ESTA FUNCION RECIVE POR PARAMETRO EL EVENTO
      Y EL NOMBRE DEL INPUT PARA IDENTIFICAR
      A QUE INPUT PERTENECE EL CAMBIO
    */
    ...mapActions([
      'getBrokers',
      'getCedents',
      'loadContacts',
      'getCatalogByName',
      'filterContactsByType',
      'getBrokersAndCedents',
      'getCatalogByDocuments',
      'resetContactsInformation',
      'checkSubscriptionStored',
      'resetAccountInformation',
    ]),
    ...mapMutations(['setDocuments']),
    changeTypeOfRisk(text) {
      const newdocs = [];
      this.documents.forEach((item) => {
        if (item.idrisk === this.accountInformation.typeOfRisk.id) {
          item.text = 'Upload the next document';
          newdocs.push(item);
        }
      });
      this.setDocuments(newdocs);
      this.$emit('changeDocuments', text);
    },
    emitInput(text, inputName) {
      this.$emit(inputName, text);
    },
    enableActivities() {
      this.activityDisabled = false;
    },
    resetActivities() {
      this.$v.accountInformation.activity.$model = '';
    },
    toggle() {
      this.$nextTick(() => {
        if (this.selectAllContacts) {
          this.selectedItems = [];
        } else {
          this.selectedItems = this.contactsItems.map((v) => v.id).slice();
        }
      });
    },
    addValue() {
      const isIncluded = this.selectedItems.includes(this.value);
      if (!isIncluded) this.selectedItems.push(this.value);
      this.$nextTick(() => {
        this.value = null;
      });
    },
    removeValue(id) {
      const item = this.selectedItems.findIndex((v) => v.id == id);
      this.selectedItems.splice(item, 1);
    },
    filterAutocomplete(item, queryText, itemText) {
      return (
        item.name.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) >
          -1 ||
        item.email.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) >
          -1
      );
    },
    async initData() {
      /* set loadings (data) */
      const lpa = 'loadingPanel';
      const lri = 'loadingRisks';
      const lco = 'loadingCountries';
      const lac = 'loadingActivities';
      const lcu = 'loadingCurrencies';
      const lbr = 'loadingBrokers';
      const lce = 'loadingCedents';
      const lcn = 'loadingContacts';
      /* loaders to true */
      this[lpa] = !this[lpa];
      this[lri] = !this[lri];
      this[lco] = !this[lco];
      this[lac] = !this[lac];
      this[lcu] = !this[lcu];
      this[lbr] = !this[lbr];
      this[lce] = !this[lce];
      this[lcn] = !this[lcn];
      /* obtención de catálogos */
      await this.getCatalogByName({ name: 'activities' });
      await this.getCatalogByName({ name: 'countries' });
      await this.getCatalogByName({ name: 'risk_type' });
      await this.getCatalogByName({ name: 'currencies' });
      await this.getBrokers();
      await this.getCedents();
      // await this.getBrokersAndCedents()
      await this.getCatalogByDocuments({ name: 'documents' });

      await this.checkSubscriptionStored();

      /* loaders to false */
      this[lac] = false;
      this[lco] = false;
      this[lri] = false;
      this[lcu] = false;
      this[lbr] = false;
      this[lce] = false;
      this[lpa] = false;

      if (this.accountInformation.typeOfRisk) {
        this.activityDisabled = false;
      }

      if (this.$route.params && this.$route.params.subscriptionId) {
        this.selectedItems = this.$store.getters.contactsInformation;
      }
    },
  },
};
</script>
<style lang="less" scoped>
@import '~@/assets/style/AccordionStyle.less';

.w-100 {
  width: 100% !important;
}
@media (max-width: 640px) {
  .ExpansionComponent {
    margin-top: 0px !important;
  }
}
</style>

<template>
  <v-expansion-panels class="ExpansionComponent ExpansionBordered mt-6">
    <v-expansion-panel :disabled="loadingPanel">
      <!--TITULO DEL ACORDEON-->
      <v-expansion-panel-header @click="changeStateCorrespondenceDoc()" class="ExpansionTitle" expand-icon="mdi-plus-circle-outline">
        Documents
        <div class="ExpansionState HideOnMovil">
          {{ stateExpansiveMessageDoc }}
        </div>
        <template v-slot:actions>
          <v-icon class="iconExpand">
            {{ iconExpansiveDoc }}
          </v-icon>
        </template>
      </v-expansion-panel-header>

      <!--CONTENIDO DEL ACORDEON-->
      <v-expansion-panel-content v-if="docs.length > 0">
        <div class="ExpandContent">
          <div class="TitleFiles">{{ accountInformation.typeOfRisk.key }} Documents:</div>
          <div class="InputsContentFh">
            <!--INPUTS DE ARCHIVOS-->
            <div class="InputFileContent mb-4" v-for="item in docs" :key="item.id">
              <!--LABEL-->
              <label class="InputFileLabel  d-flex align-center justify-center">
                <input @change="loadImage($event, 'input1', item)" class="HideInputFile" type="file" />

                <div v-if="item.text == 'Upload the next document'" class="emptyFileInfo d-flex justify-center align-center flex-column">
                  <p>
                    Upload de <b>{{ item.name }}</b> document
                  </p>
                </div>
                <div v-else class="NameFileCont">
                  <div class="FileName">
                    {{ item.name }}
                  </div>
                  <div class="FileImage">
                    <img class="image" src="@/assets/img/document.png" />
                  </div>
                </div>
              </label>

              <!--BORRAR-->
              <div @click="DeleteFile('input1', item)" class="InputDeletContBtn d-flex justify-center align-center">
                <v-icon>
                  mdi-trash-can-outline
                </v-icon>
              </div>

              <!--DESCARGAR-->
              <div class="DownloadCont d-flex justify-center align-center" @click="download(item)">
                <v-icon>
                  mdi-download
                </v-icon>
              </div>
            </div>
          </div>
        </div>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>
<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import { stateExpansiveManager } from '@/mixins/subscription.js';
import subscription from '../../../store/actions/subscription';
export default {
  name: 'FilesSubmission',
  mixins: [stateExpansiveManager],
  data() {
    return {
      /* loaders */
      loadingPanel: false,
      loadingRisks: false,
      loadingBrokers: false,
      loadingCedents: false,
      loadingCountries: false,
      loadingActivities: false,
      loadingCurrencies: false,
      //VARIABLES DE LOS ARCHIVOS
    };
  },
  props: {
    //DEFINE QUE ESTILOS AÑADIMOS A LOS LINKS
    link1: String,
    link2: String,
    showFileUp: Boolean,
  },
  watch: {},

  computed: {
    ...mapGetters(['documents', 'document', 'accountInformation', 'subscription_id', 'nameReference', 'type', 'docs', 'risk_type', 'downloadDocUrl']),
  },
  async mounted() {
    /* set loadings (data) */
    const lpa = 'loadingPanel';

    /* loaders to true */
    this[lpa] = !this[lpa];

    await this.checkSubscriptionStored();
    await this.getCatalogByDocuments({ name: 'documents' });
    await this.loadDocs();

    this[lpa] = false;
  },
  methods: {
    ...mapActions([
      'registerIdSubscription',
      'updateDataSubscription',
      'save',
      'upload',
      'delete',
      'DocumentsSubscriptionRisk',
      'checkSubscriptionStored',
      'getCatalogByDocuments',
      'DownloadDoc',
      'downloadDocument',
    ]),
    ...mapMutations(['setLoading', 'addNotification', 'setDocuments']),
    /*
    CARGAMOS Y GUARDAMOS LAS IMAGENES,
    RECIBE POR PARAMETROS EL EVENTO Y EL
    NOMBRE DEL INPUT CON EL QUE TRABAJAREMOS
    */

    loadImage(ev, inputName, item) {
      var vm = this;

      //OBTENEMOS EL NOMBRE DEL ARCHIVO
      const file = ev.target.files[0];
      //const fileName = file.name;
      const fileSize = file.size;
      this.setLoading();
      //OBTENEMOS EL ARCHIVO
      if (fileSize < 30000000) {
        const reader = new FileReader();
        reader.onload = (e) => {
          let blobImage = new Blob([e.target.result]);
          let imageUrl = window.URL.createObjectURL(blobImage);

          if (this.subscription_id != null) {
            this.saveFile(ev.target.files[0], item, inputName);
          } else {
            this.registerIdSubscription({}).finally(() => {
              this.saveFile(ev.target.files[0], item, inputName);
            });
          }

          item.text = item.name;
          this.technicalMemory = imageUrl;
        };
        reader.readAsDataURL(file);
      } else {
        this.addNotification({ type: 'danger', text: 'Max 30 MB' });
        this.setLoading();
      }
    },
    saveFile(file, item, inputName) {
      var vm = this;
      /* GUARDAMOS
          LA INFORMACIÓN DEL ARCHIVO
        */
      var datos = file.name.split('.', 2);

      this.nameReference
        ? this.save({ document_id: item.id, subscription_id: this.subscription_id, type: datos[1] }).finally(() => {
            this.upload({ file: file, path: 'COT_' + this.subscription_id + '/' + this.type + '/' + this.document })
              .then((res) => {
                res.error ? this.DeleteFile(inputName, item) : (item.uri = res.singleUpload.uri), (item.document = this.document);
                this.setLoading();
              })
              .catch((e) => {
                this.setLoading();
              });
          })
        : this.updateDataSubscription({ reference: 'COT-' + this.subscription_id }).finally(() => {
            this.save({ document_id: item.id, subscription_id: this.subscription_id, type: datos[1] }).finally(() => {
              this.upload({ file: file, path: 'COT_' + this.subscription_id + '/' + this.type + '/' + this.document })
                .then((res) => {
                  res.error ? this.DeleteFile(inputName, item) : (item.uri = res.singleUpload.uri), (item.document = this.document);
                  this.setLoading();
                })
                .catch((e) => {
                  this.setLoading();
                });
            });
          });

      vm.$forceUpdate();
    },
    /*
    ESTA FUNCION ELIMINA LOS ARCHIVOS QUE YA
    FUERON CARGADOS, RECIVE POR PARAMETRO EL
    NOMBRE DEL INPUT PARA IDENTIFICAR
    EL ARCHIVO QUE ELIMINAREMOS
    */
    DeleteFile(inputName, item) {
      var vm = this;
      try {
        this.setLoading();
        this.delete(item.document)
          .then((res) => {
            res.error ? this.setLoading() : (item.text = 'Upload the next document');
            this.$emit('deleteImage', {
              imageNumber: inputName,
            });
            vm.$forceUpdate();
          })
          .catch((e) => {
            this.setLoading();
          });
      } catch (e) {
        this.setLoading();
        console.error('Could not be deleted' + e);
      }
    },
    async download(item) {
      if (item.uri) {
        this.downloadDocument(item.uri, item.text);
      } else if (item.doc_s3) {
        await this.DownloadDoc({ path: 'COT_' + this.subscription_id + '/' + this.type + '/' + item.doc_s3 });
        this.downloadDocument(this.downloadDocUrl);
      }
    },
    async loadDocs() {
      if (this.subscription_id) {
        await this.DocumentsSubscriptionRisk({ subscription_id: parseInt(this.subscription_id), documents: this.documents, typeOfRisk: this.accountInformation.typeOfRisk });
        this.$forceUpdate();
      } else {
        await this.setDocuments([]);
        this.$forceUpdate();
      }
    },
  },
};
</script>
<style lang="less" scoped>
//ESTILOS GENERALES DEL ACORDEON
@import '~@/assets/style/AccordionStyle.less';
@import '~@/assets/style/FilesStyle.less';
</style>

<template>
  <div class="
    NewRenewalComponent 
    d-flex 
    justify-center 
    align-center 
    mt-2">
    
    <div 
    	class="
    		NewRenewalTextContent
    		LeftButton
    	" 
    	:class="[type == 1 ? 'active shadow' : '']" 
    	@click="newOrRenewal({ value: 1 })"
    	>
    		<div 
          class="NewRenSn"
          :class="[type == 1 ? 'bottomline' : '']"
          >
            New Account
        </div>
    </div>

    <div 
    	class="
    		NewRenewalTextContent
    		RightButton
    	" 
    	:class="[type == 2 ? 'active shadow2' : '']" 
    	@click="newOrRenewal({ value: 2 })"
    	>
    	<div 
          class="NewRenSn"
          :class="[type == 2 ? 'bottomline' : '']"
          >
            Account Renewal
        </div>
    </div>

    <CloseAccount />

  </div>
</template>
<style lang="less" scoped>
@import "~@/assets/style/Accounts/NewRenewal.less";
</style>
<script>
import CloseAccount from '@/components/subscription/CloseAccount.vue'
import { mapActions, mapGetters } from 'vuex'

export default {
	computed: {
		...mapGetters(['type']),
	},
	methods: {
		...mapActions(['newOrRenewal']),
	},
  components: {
    CloseAccount
  },
}
</script>

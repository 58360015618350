<template>
  <v-expansion-panels class="ExpansionComponent ExpansionBordered mt-6">
    <v-expansion-panel :disabled="loadingPanel">
      <!--TITULO DEL ACORDEON-->
      <v-expansion-panel-header @click="changeStateCorrespondence()" class="ExpansionTitle" expand-icon="">
        Correspondence
        <div class="ExpansionState HideOnMovil">
          {{ stateExpansiveMessageCorr }}
        </div>
        <template v-slot:actions>
          <v-icon class="iconExpand">
            {{ iconExpansiveCorr }}
          </v-icon>
        </template>
      </v-expansion-panel-header>

      <!--CONTENIDO DEL ACORDEON-->
      <v-expansion-panel-content>
        <div class="ExpandContent">
          <!--INPUTS DE ARCHIVOS-->
          <div class="InputsContentFh">
            <div class="InputFileContent mb-4" v-for="(item, index) in correspondenceDocuments" :key="index">
              <!--LABEL-->
              <label class="InputFileLabel d-flex align-center justify-center">
                <input @change="loadImage($event, 'input1', item)" class="HideInputFile" type="file" />

                <div v-if="item.text == 'Upload the next document'" class="emptyFileInfo d-flex justify-center align-center flex-column">
                  <p>
                    Upload the <b>Correspondence {{ item.value }}</b> document
                  </p>
                </div>
                <div v-else class="NameFileCont">
                  <div class="FileName">
                    {{ item.text }}
                  </div>
                  <div class="FileImage">
                    <img class="image" src="@/assets/img/document.png" />
                  </div>
                </div>
              </label>

              <!--BORRAR-->
              <div @click="DeleteFile(index, item)" class="InputDeletContBtn d-flex justify-center align-center">
                <v-icon>mdi-trash-can-outline</v-icon>
              </div>

              <!--DESCARGAR-->
              <div class="DownloadCont d-flex justify-center align-center" @click="download(item)">
                <v-icon>
                  mdi-download
                </v-icon>
              </div>
            </div>

            <div class="emptyFileContent d-flex justify-start align-center">
              <v-btn text rounded class="moreButton" @click="addCorrespondence()">
                <v-icon class="mr-2">
                  mdi-plus-circle
                </v-icon>
                Add more documents
              </v-btn>
            </div>
            <div class="emptyFileContent HideOnMovil"></div>
          </div>
        </div>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>
<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import { stateExpansiveManager } from '@/mixins/subscription.js';
export default {
  name: 'CarDocuments',
  mixins: [stateExpansiveManager],
  data() {
    return {
      //VARIABLES DE LOS ARCHIVOS
      fileName1: '',
      technicalMemory: null,
      loadingPanel: false,
    };
  },
  computed: {
    ...mapGetters(['correspondenceDocuments', 'document', 'subscription_id', 'nameReference', 'type', 'downloadDocUrl']),
  },
  async mounted() {
    /* set loadings (data) */
    const lpa = 'loadingPanel';

    /* loaders to true */
    this[lpa] = !this[lpa];

    await this.loadDocs();

    this[lpa] = false;
  },
  methods: {
    ...mapActions([
      'setCorrespondenceDocument',
      'registerIdSubscription',
      'updateDataSubscription',
      'save',
      'upload',
      'downloadDocument',
      'delete',
      'CorrespondenceDocumentsSubscription',
      'DownloadDoc',
    ]),
    ...mapMutations(['setLoading']),
    /*
    CARGAMOS Y GUARDAMOS LAS IMAGENES,
    RECIBE POR PARAMETROS EL EVENTO Y EL 
    NOMBRE DEL INPUT CON EL QUE TRABAJAREMOS
    */
    loadImage(ev, inputName, item) {
      var vm = this;

      //OBTENEMOS EL NOMBRE DEL ARCHIVO
      const file = ev.target.files[0];
      const fileSize = file.size;
      this.setLoading();
      //OBTENEMOS EL ARCHIVO
      if (fileSize < 30000000) {
        const reader = new FileReader();
        reader.onload = (e) => {
          let blobImage = new Blob([e.target.result]);
          let imageUrl = window.URL.createObjectURL(blobImage);

          if (this.subscription_id != null) {
            this.saveFile(ev.target.files[0], item, inputName);
          } else {
            this.registerIdSubscription({}).finally(() => {
              this.saveFile(ev.target.files[0], item, inputName);
            });
          }

          item.text = item.name;
          this.technicalMemory = imageUrl;
        };
        reader.readAsDataURL(file);
      } else {
        this.addNotification({ type: 'danger', text: 'Max 30 MB' });
        this.setLoading();
      }
    },
    saveFile(file, item, inputName) {
      var vm = this;
      /* GUARDAMOS
          LA INFORMACIÓN DEL ARCHIVO
        */
      var datos = file.name.split('.', 2);
      this.nameReference
        ? this.save({ document_id: item.id, subscription_id: this.subscription_id, type: datos[1] }).finally(() => {
            this.upload({ file: file, path: 'COT_' + this.subscription_id + '/' + this.type + '/' + this.document })
              .then((res) => {
                res.error ? this.DeleteFile(item) : (item.uri = res.singleUpload.uri), (item.doc_s3 = this.document);
                this.setLoading();
              })
              .catch((e) => {
                this.setLoading();
              });
          })
        : this.updateDataSubscription({ reference: 'COT-' + this.subscription_id }).finally(() => {
            this.save({ document_id: item.id, subscription_id: this.subscription_id, type: datos[1] }).finally(() => {
              this.upload({ file: file, path: 'COT_' + this.subscription_id + '/' + this.type + '/' + this.document })
                .then((res) => {
                  res.error ? this.DeleteFile(item) : (item.uri = res.singleUpload.uri), (item.doc_s3 = this.document);
                  this.setLoading();
                })
                .catch((e) => {
                  this.setLoading();
                });
            });
          });

      vm.$forceUpdate();
    },
    /*
    ESTA FUNCION ELIMINA LOS ARCHIVOS QUE YA
    FUERON CARGADOS, RECIVE POR PARAMETRO EL
    NOMBRE DEL INPUT PARA IDENTIFICAR
    EL ARCHIVO QUE ELIMINAREMOS
    */
    DeleteFile(index, item) {
      if (item.text == 'Upload the next document') {
        this.correspondenceDocuments.splice(index, 1);
      } else {
        this.setLoading();
        try {
          this.delete(item.doc_s3)
            .then((res) => {
              res.error ? this.setLoading() : this.correspondenceDocuments.splice(index, 1);
              this.setLoading();
            })
            .catch((e) => {
              this.setLoading();
            });
        } catch (e) {
          this.setLoading();
          console.error('Could not be deleted');
        }
      }
    },
    addCorrespondence() {
      var correspondenceDoc = {
        description: 'Correspondence Documents',
        id: 21,
        key: 'correspondence',
        name: 'Correspondence Document',
        text: 'Upload the next document',
        value: this.correspondenceDocuments.length + 1,
      };
      this.setCorrespondenceDocument(correspondenceDoc);
    },

    async download(item) {
      if (item.uri) {
        this.downloadDocument(item.uri, item.text);
      } else if (item.doc_s3) {
        await this.DownloadDoc({ path: 'COT_' + this.subscription_id + '/' + this.type + '/' + item.doc_s3 });
        this.downloadDocument(this.downloadDocUrl);
      }
    },
    async loadDocs() {
      if (this.subscription_id) {
        await this.CorrespondenceDocumentsSubscription({ subscription_id: parseInt(this.subscription_id) });
      }
    },
  },
};
</script>
<style lang="less" scoped>
//ESTILOS GENERALES DEL ACORDEON
@import '~@/assets/style/AccordionStyle.less';
@import '~@/assets/style/FilesStyle.less';
</style>
